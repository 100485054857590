import React, {useState} from "react";
import { useSelector } from "react-redux";
import {
    lighten,
    Button,
    Divider,
    Link,
    makeStyles,
    Paper,
    Typography,
    FormControl,
    TextField,
} from "@material-ui/core";
import { Link as RouterLink } from "react-router-dom";
import { useHistory } from "react-router";
import { useTranslation } from "react-i18next";
import SearchIcon from '@material-ui/icons/Search';

const useStyles = makeStyles((theme) => ({
    emptyContainer: {
        bottom: "0",
        height: "300px",
        margin: "50px auto",
        width: "300px",
        color: lighten(theme.palette.text.disabled, 0.4),
        textAlign: "center",
        paddingTop: "20px",
    },
    paper: {
        marginTop: theme.spacing(8),
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        padding: `${theme.spacing(2)}px ${theme.spacing(3)}px ${theme.spacing(
            3
        )}px`,
    },
    link: {
        marginTop: "20px",
        display: "flex",
        width: "100%",
        justifyContent: "space-between",
    },
    submit: {
        marginTop: theme.spacing(3),
    },
}));

function Front() {
    const classes = useStyles();
    const title = useSelector((state) => state.siteConfig.title);
    const registerEnabled = useSelector(
        (state) => state.siteConfig.registerEnabled
    );
    const { t } = useTranslation();

    const [keywd, setKeywd] = useState("");
    const history = useHistory();
    const search = (e) => {
        history.push(
            "/search?keywords=" + encodeURIComponent(keywd)
        );
    };
    return (
        <div className={classes.emptyContainer}>
            <Paper className={classes.paper}>
                <Typography component="h1" variant="h5">
                    {title} 导航页
                </Typography>

                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    href="/s/4ZUW"
                >
                    东方狗下载站（国际）
                </Button>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                        const w = window.open('about:blank');
                        w.location.href = 'https://cn.thdog.moe';
                    }}
                >
                    国内分流（推荐）
                </Button>
                <Button
                    type="button"
                    fullWidth
                    variant="contained"
                    color="primary"
                    className={classes.submit}
                    onClick={() => {
                        const w = window.open('about:blank');
                        w.location.href = 'https://bb.lilywhite.cc';
                    }}
                >
                    留言板（新）
                </Button>

                <form className={classes.form} onSubmit={search}>
                    <FormControl margin="normal" required fullWidth>
                        <TextField
                            variant={"outlined"}
                            label="分享的文件名"
                            inputProps={{
                                name: "keyword",
                                type: "keyword",
                                id: "keyword",
                            }}
                            onChange={(e) => setKeywd(e.target.value)}
                            value={keywd}
                        />
                    </FormControl>
                    <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        color="primary"
                        className={classes.submit}
                        startIcon={<SearchIcon />}
                    >
                        搜索公开分享
                    </Button>
                </form>
                <Typography variant="body1" color="error">
                    注意：下载文件无需注册或登录。
                </Typography>

                <Divider />
                <div className={classes.link}>
                    {registerEnabled && (
                        <Link component={RouterLink} to={"/signup"}>
                            {t("login.signUpAccount")}
                        </Link>
                    )}
                    <div>
                        <Link href={"/login"}>登录</Link>
                    </div>
                </div>
            </Paper>
        </div>
    );
}

export default Front;
